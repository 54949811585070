import { Component, OnInit } from '@angular/core';

import { Tag } from './tag.model';
import { TagService } from './tag.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  tags: Tag[];
  loadingTags = false;
  selectedTag: Tag;
  showTagForm = false;
  serverMessage  = '';

  constructor(private tagService: TagService) {}

  ngOnInit() {
    this.getTags();
  }

  getTags() {
    this.loadingTags = true;
    this.tagService.getTags().
        subscribe(
          (tags: Tag[]) => {
            this.tags = tags;
            this.loadingTags = false;
            if (this.tags.length === 0) {
              this.serverMessage = 'No tags found!';
            } else {
              this.serverMessage = '';
            }
          },
          error => {
            this.notifyRequestError(error);
          });
  }

  onShowTagForm(tag: Tag): void {
    this.selectedTag = tag;
    this.showTagForm = true;
  }

  onFinishTagFormSubmition (submitted) {
    this.showTagForm = false;
    this.getTags();
  }

  private notifyRequestError(error) {
    this.loadingTags = false;
    this.serverMessage = error;
  }
}
