import { Component, OnInit } from '@angular/core';

import { CollectionService } from '../collection.service';
import {
  CREATED_DATE_SESSION_TABLE_COLUMN,
  DEFAULT_PAGINATION_LIMIT, DESCRIPTION_SESSION_TABLE_COLUMN,
  EVENTS_SESSION_TABLE_COLUMN
} from './../../shared/samla_constants';
import { Collection } from './../collection.model';
import { CollectionResponse } from './../collectionResponse.interface';

@Component({
  selector: 'app-transfer-collection',
  templateUrl: './transfer-collection.component.html'
})
export class TransferCollectionComponent implements OnInit {

  collections: Collection[];
  title = 'Transfer Collections';
  collectionTableColumns: string[] = [  CREATED_DATE_SESSION_TABLE_COLUMN,
                           DESCRIPTION_SESSION_TABLE_COLUMN,
                           EVENTS_SESSION_TABLE_COLUMN ];
  loading: boolean;
  total = 0;
  messageFromServer = '';
  limit = DEFAULT_PAGINATION_LIMIT;

  constructor(private collectionService: CollectionService) { }

  ngOnInit() {
    this.getCollections(1);
  }

  getCollections(page: number) {
    this.loading = true;
    this.messageFromServer = '';
    this.collectionService.getTranferCollections(page, DEFAULT_PAGINATION_LIMIT)
        .subscribe(
          (collectionResponse: CollectionResponse) => {
            if (collectionResponse.results.length === 0) {
              this.messageFromServer = 'No collections found!';
            }
            this.collections = [...collectionResponse.results];
            this.total = collectionResponse.total;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  onChangePage(page: number) {
    this.getCollections(page);
  }

  onRefresh(page: number) {
    this.getCollections(page);
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }


}
