import {DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NAV_DROPDOWN_DIRECTIVES} from './shared/nav-dropdown.directive';

// External libraries
import {AgmCoreModule} from '@agm/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {ModalModule} from 'ngx-bootstrap';

import {SIDEBAR_TOGGLE_DIRECTIVES} from './shared/sidebar.directive';
import {AsideToggleDirective} from './shared/aside.directive';
import {BreadcrumbsComponent} from './shared/breadcrumb.component';

// Services
import {SamlaService} from './samla.service';
import {TagService} from './tag/tag.service';
import {EventService} from './event/event.service';
import {VehicleService} from './vehicles/vehicle.service';
import {AuthGuard} from './auth/auth-guard.service';
import {CollectionService} from './collection/collection.service';
import {ProcessedFileService} from './processed_file/processed-file.service';
import {DomainService} from './domains/domain.service';

// Tags
import {TagComponent} from './tag/tag.component';
import {TagEditComponent} from './tag/tag-edit/tag-edit.component';

// Events
import {EventComponent} from './event/event.component';
import {EventCardComponent} from './event/event-card/events-card.component';
import {EventTableComponent} from './event/events-table/event-table.component';
import {EventDomainComponent} from './event/events-table/event-domain/event-domain.component';
import {EventVisibilityComponent} from './event/events-table/event-visbility/event-visibility.component';
import {EventToolbarComponent} from './event/event-toolbar/event-toolbar.component';
import {VisibilityToolbarButtonComponent} from './event/event-toolbar/visibility-toolbar-button/visibility-toolbar-button.component';
import {EventDetailComponent} from './event/event-detail/event-detail.component';
import {EventDescriptionComponent} from './event/event-detail/event-description/event-description.component';
import {EventCommentComponent} from './event/event-detail/event-comment/event-comment.component';
import {EventFilterComponent} from './event/event-filter/event-filter.component';
import {TaggingMultipleEventsComponent} from './event/tagging-multiple-events/tagging-multiple-events.component';
import {EventDownloadComponent} from './event/events-table/event-download/event-download.component';
import {EventDownloadPopupComponent} from './event/events-table/event-download/event-download-popup/event-download-popup.component';

// Vehicles
import {VehiclesComponent} from './vehicles/vehicles.component';
import {EventsVehiclesComponent} from './vehicles/events-vehicles/events-vehicles.component';

// Routing Module
import {AppRoutingModule} from './app.routing.module';

// Layouts
import {FullLayoutComponent} from './layouts/full-layout.component';

// Collections
import {TransferCollectionComponent} from './collection/transfer-collection/transfer-collection.component';
import {SystemCollectionComponent} from './collection/system-collection/system-collection.component';
import {CollectionTableComponent} from './collection/collection-table/collection-table.component';
import {CollectionDetailComponent} from './collection/collection-detail/collection-detail.component';
import {EventsCollectionComponent} from './collection/collection-detail/events-collection/events-collection.component';
import {CollectionCardComponent} from './collection/collection-card/collection-card.component';

// Others
import {PaginationComponent} from './pagination/pagination.component';
import {DatexPipe} from './pipes/datex.pipe';
import {ShortenPipe} from './pipes/shorten.pipe';
import {DropdownDirective} from './shared/dropdown.directive';
import {ShareLogComponent} from './share-logs/share-log.component';
import {PopupDirective} from './shared/popup.directive';
import {SamlaDesktopComponent} from './samla-desktop/samla-desktop.component';

// Domain
import {DomainsComponent} from './domains/domains.component';
import {DomainEditFormComponent} from './domains/domain-edit-form/domain-edit-form.component';

// Pages
import {P403Component} from './unauthorized/403.component';
import {P404Component} from './notfound/404.component';

// Angular Keycloak
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';

// Interceptors
import {HttpErrorInterceptor} from './http-error.interceptor';
import {HttpRequestInterceptor} from './http-request.interceptor';
import {HttpMockRequestInterceptor} from './mock/mock-request.interceptor';

import {environment} from '../environments/environment';
import {TimeUnitPipe} from './pipes/time-unit.pipe';
import { EventGuidColumnComponent } from './event/event-guid-column/event-guid-column.component';

import { EventStatusComponent } from './event/event-status/event-status.component';
import { EventStatusService } from './event/event-status/event-status.service';

export const isMock = environment.mock;
const keycloakService: KeycloakService = new KeycloakService();

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD6hHuNg8ub01QH6Pf_K7ColOwJU3UHp0s'
    }),
    NgSelectModule,
    MyDateRangePickerModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    KeycloakAngularModule,
    BrowserAnimationsModule
  ],
  declarations: [
    AppComponent,
    FullLayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    TagComponent, TagEditComponent,
    EventComponent, EventCardComponent, EventTableComponent, PaginationComponent, EventDomainComponent,
    DatexPipe, ShortenPipe, TimeUnitPipe, EventVisibilityComponent, EventToolbarComponent,
    VisibilityToolbarButtonComponent, EventDetailComponent, EventDescriptionComponent, EventCommentComponent,
    EventFilterComponent, TaggingMultipleEventsComponent,
    VehiclesComponent, EventsVehiclesComponent, P403Component, P404Component,
    TransferCollectionComponent, SystemCollectionComponent, CollectionTableComponent,
    CollectionDetailComponent, EventsCollectionComponent, CollectionCardComponent, EventDownloadComponent,
    DropdownDirective, DomainsComponent, DomainEditFormComponent, ShareLogComponent, EventGuidColumnComponent, EventStatusComponent,
    EventDownloadPopupComponent, PopupDirective, SamlaDesktopComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: isMock ? HttpMockRequestInterceptor : HttpRequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: KeycloakService, useValue: keycloakService},
    SamlaService, TagService, TagEditComponent, EventService, VehicleService,
    AuthGuard, CollectionService, ProcessedFileService, DomainService, EventStatusService
],
 entryComponents: [AppComponent]
})

export class AppModule implements DoBootstrap {
  async ngDoBootstrap(app) {
    const { keycloakConfig } = environment;
    try {
        await keycloakService.init({
          config: keycloakConfig,
          loadUserProfileAtStartUp: false,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          bearerPrefix: 'Bearer'
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}
