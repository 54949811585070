import { Directive } from '@angular/core';
import { HostListener, HostBinding } from '@angular/core/';

@Directive({
    selector: '[appPopup]'
})
export class PopupDirective {
    @HostBinding('class.open') isOpen = false;

    @HostListener('click', ['$event']) toggleOpen($event: MouseEvent) {
      $event.stopPropagation();
      this.isOpen = !this.isOpen;
    }
}
