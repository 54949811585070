import { Component, OnInit } from '@angular/core';

import { SamlaService } from './../samla.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {

  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};
  public username: string;
  public isAdmin: boolean;
  public samlaVersion: string;
  public samlaCopyright: string;

  public bannerView: boolean = true;

  constructor(private keycloakService: KeycloakService,  private samlaService: SamlaService) {}

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  async ngOnInit() {
    this.samlaCopyright = '© 2019-' + new Date().getFullYear();
    if (await this.keycloakService.isLoggedIn()) {
      const userProfile = await (await this.keycloakService.loadUserProfile());
      this.username = userProfile.firstName + ' ' + userProfile.lastName;
      this.isAdmin = this.keycloakService.getUserRoles().includes('admin');
      this.samlaService.getSamlaVersion()
        .subscribe(
          (version: string) => {
            this.samlaVersion = version;
          },
          (error) => {
            console.log('Failed to fetch SAMLA version: ', error);
          }
        );
      (<any>window).plausible('User Login', { props: { username: this.username } });
    }
  }

  async logOut() {
    await this.keycloakService.logout();
  }

  async closeBanner() {
    this.bannerView = false;
  }
}
