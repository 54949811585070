import {EVENT_COMMENT_LENGTH} from './../../../shared/samla_constants';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EventService} from '../../event.service';
import {Event} from '../../model/event.model';

@Component({
  selector: 'app-event-comment',
  templateUrl: './event-comment.component.html',
  styleUrls: ['./event-comment.component.scss']
})
export class EventCommentComponent implements OnInit {
  @Input() event: Event;
  showForm = false;
  submitting = false;
  serverErrorOnSubmitting = false;
  serverErrorMessage = '';
  commentForm: FormGroup;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.initCommentForm();
  }
  // add/edit comment
  private initCommentForm() {
    const comment = this.event.comment;
    this.commentForm = new FormGroup({
      'comment': new FormControl(comment, [Validators.required, Validators.maxLength(EVENT_COMMENT_LENGTH)])
    });
  }
  onSubmitComment(): void {
    this.submitting = true;
    this.serverErrorMessage = '';
    this.serverErrorOnSubmitting = false;
    const comment = this.commentForm.value.comment;
    this.eventService.updateComment(this.event.id, comment)
        .subscribe(
          resp => {
            if (resp.status === 'SUCCESS') {
              this.commentEventLocally(this.event, comment);
              this.submitting = false;
              this.onCancel();
            }
          },
          (error) => {
            this.serverErrorOnSubmitting = true;
            this.submitting = false;
            this.serverErrorMessage = error;
          }
        )
  }

  commentEventLocally(event: Event, comment: string) {
    event.comment = comment;
  }

  onCancel(): void {
    this.serverErrorOnSubmitting = false;
    this.showForm = false;
    this.commentForm.setValue({
      'comment': this.event.comment
    });
  }
}
