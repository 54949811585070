import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Layouts
import {FullLayoutComponent} from './layouts/full-layout.component';

// Events
import {EventComponent} from './event/event.component';

// Collections
import {TransferCollectionComponent} from './collection/transfer-collection/transfer-collection.component';
import {SystemCollectionComponent} from './collection/system-collection/system-collection.component';

// Tags
import {TagComponent} from './tag/tag.component';

// Auth
import {AuthGuard} from './auth/auth-guard.service';
import {SamlaDesktopComponent} from './samla-desktop/samla-desktop.component';

// Vehicles
import {VehiclesComponent} from './vehicles/vehicles.component';
import {EventsVehiclesComponent} from './vehicles/events-vehicles/events-vehicles.component';

// Domains
import {DomainsComponent} from './domains/domains.component';

// Pages
import {P403Component} from './unauthorized/403.component';
import {P404Component} from './notfound/404.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['user', 'admin']},
    children: [
      { path: 'events', component: EventComponent },
      { path: 'events/:guid', component: EventComponent },
      { path: 'collections/transfer', component: TransferCollectionComponent },
      { path: 'collections/system', component: SystemCollectionComponent },
      { path: 'tags', component: TagComponent, canActivate: [AuthGuard], data: { roles: ['admin']} },
      { path: 'vehicles', component: VehiclesComponent },
      { path: 'vehicles/:id/:name/events', component: EventsVehiclesComponent },
      { path: 'domains', component: DomainsComponent, canActivate: [AuthGuard], data: { roles: ['admin']} },
      { path: 'samlaDesktop', component: SamlaDesktopComponent }
    ]
  },
  { path: 'unauthorized', component: P403Component },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
