import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlapiEventResponse } from '../model/apiResponse.interface';

import { Event } from '../model/event.model';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.scss'],
})
export class EventTableComponent implements OnInit {
  @Input() events: Event[];
  @Input() totalTagsCount: number;
  @Input() loading: boolean;
  @Input() serverGetEventsMessage = '';
  @Input() slapiResponse: SlapiEventResponse;

  @Output() goSelectEvents = new EventEmitter<Event[]>();

  selectedColumns = 8;
  allEventSelected = false;
  showActionsColumn = true;

  constructor() {}

  ngOnInit() {
  }

  onSelectEvent(event: Event) {
    event.isSelected = !event.isSelected;
  }

  // selectable column feature. Updates the amount of columns cheched
  onSelectedColumn(columnCheckboxInput: HTMLInputElement): void {
    if (columnCheckboxInput.checked) {
      this.selectedColumns++;
    } else {
      this.selectedColumns--;
    }
  }

  private getSelectedEvents(): Event[] {
    return this.events.filter((event) => event.isChecked);
  }

  onToggleAllEventsCheckbox(): void {
    let selectedEvents: Event[] = [];

    for (let i = 0; i < this.events.length; i++) {
      this.events[i].isChecked = this.allEventSelected;
    }

    if (this.allEventSelected) {
      selectedEvents = [...this.events];
    }

    this.goSelectEvents.emit(selectedEvents);
  }

  onToggleEventCheckbox() {
    const selectedEvents = this.getSelectedEvents();

    if (selectedEvents.length === this.events.length) {
      this.allEventSelected = true;
    } else {
      this.allEventSelected = false;
    }

    this.goSelectEvents.emit(selectedEvents);
  }

  stopEventPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }
}
