import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import * as users from './users.json';
import * as events from './events.json';
import * as tags from './tags.json';
import * as vehicles from './vehicles.json';
import * as visibilities from './visibilities.json';
import * as collections_system from './collections_system.json';
import * as collections_transfer from './collections_transfer.json';
import * as domains from './domains.json';
import * as slapiFiles from './slapiFiles.json'

import {SERVER_API_VERSION} from 'app/shared/samla_constants.js';
import { metadataApiUrl } from 'app/shared/samla_constants.js';

const urls = [
  { url: `${SERVER_API_VERSION}/users`, json: users },
  { url: `${SERVER_API_VERSION}/events?page=1&limit=50`, json: events },
  { url: `${SERVER_API_VERSION}/events/visibilities`, json: visibilities },
  { url: `${SERVER_API_VERSION}/tags`, json: tags },
  { url: `${SERVER_API_VERSION}/vehicles`, json: vehicles },
  { url: `${SERVER_API_VERSION}/events/collections?page=1&limit=50&type=SYSTEM`, json: collections_system },
  { url: `${SERVER_API_VERSION}/events/collections?page=1&limit=50&type=TRANSFER`, json: collections_transfer },
  { url: `${SERVER_API_VERSION}/domains/flattened`, json: domains },
  { url: `${metadataApiUrl}/files`, json: slapiFiles }
];

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of urls) {
      if (request.url === element.url) {
        console.log('Loaded from json : ' + request.url);
        return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
      }
    }
    console.log('Loaded from http call :' + request.url);
    return next.handle(request);
  }
}
