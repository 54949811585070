import { Component } from '@angular/core';

@Component({
  templateUrl: '403.component.html'
})
export class P403Component {

  constructor() { }

}
