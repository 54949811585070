import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Tag } from './tag.model';

import { Injectable } from '@angular/core';
import { SERVER_API_VERSION } from 'app/shared/samla_constants';

@Injectable()
export class TagService {
  tagsUrl = `${SERVER_API_VERSION}/tags`;

  constructor(private http: HttpClient) { }

  getTags (): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.tagsUrl);
  }

  create(names: string[]): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .post(this.tagsUrl, JSON.stringify({names: names}), {headers: headers});
  }

  update(id: number, name: string): Observable<any> {
     const url = `${this.tagsUrl}/${id}`;
     const headers = new HttpHeaders().set('Content-Type', 'application/json');
     return this.http
      .put(url, JSON.stringify({name: name}), {headers: headers});
  }

}
