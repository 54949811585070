import { STOP_DATE_SESSION_TABLE_COLUMN, DESCRIPTION_SESSION_TABLE_COLUMN,
          VEHICLE_SESSION_TABLE_COLUMN, EVENTS_SESSION_TABLE_COLUMN,
          DURATION_SESSION_TABLE_COLUMN, DRIVER_SESSION_TABLE_COLUMN, DEFAULT_PAGINATION_LIMIT } from './../../shared/samla_constants';
import { OnInit, Component } from '@angular/core';

import { CollectionResponse } from './../collectionResponse.interface';
import { Collection } from './../collection.model';
import { CollectionService } from '../collection.service';
import { START_DATE_SESSION_TABLE_COLUMN } from '../../shared/samla_constants';

@Component({
  selector: 'app-system-collection',
  templateUrl: './system-collection.component.html'
})
export class SystemCollectionComponent implements OnInit {
  collections: Collection[];
  title = 'System Collections';
  collectionTableColumns: string[] = [  START_DATE_SESSION_TABLE_COLUMN, STOP_DATE_SESSION_TABLE_COLUMN, DURATION_SESSION_TABLE_COLUMN,
                           DESCRIPTION_SESSION_TABLE_COLUMN, VEHICLE_SESSION_TABLE_COLUMN, DRIVER_SESSION_TABLE_COLUMN,
                           EVENTS_SESSION_TABLE_COLUMN];
  loading: boolean;
  total = 0;
  messageFromServer = '';
  limit = DEFAULT_PAGINATION_LIMIT;

  constructor(private collectionService: CollectionService) { }

  ngOnInit() {
    this.getCollections(1);
  }

  getCollections(page: number) {
    this.loading = true;
    this.messageFromServer = '';
    this.collectionService.getSystemCollections(page, DEFAULT_PAGINATION_LIMIT)
        .subscribe(
          (collectionResponse: CollectionResponse) => {
            if (collectionResponse.results.length === 0) {
              this.messageFromServer = 'No collections found!';
            }
            this.collections = collectionResponse.results;
            this.total = collectionResponse.total;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  onChangePage(page: number) {
    this.getCollections(page);
  }

  onRefresh(page: number) {
    this.getCollections(page);
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }


}
