import { VehicleService } from './vehicle.service';
import { OnInit, Component } from '@angular/core';
import { Vehicle } from './vehicle.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  vehicles: Vehicle[];
  messageFromServer = '';
  loading = false;

  constructor(private vehicleService: VehicleService, private router: Router) { }

  ngOnInit() {
    this.getVehicles();
  }

  getVehicles() {
    this.loading = true;
    this.vehicleService.getVehicles()
        .subscribe(
          (vehicles: Vehicle[]) => {
            this.vehicles = vehicles;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }
}
