import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { SERVER_API_VERSION, ACCESS_APP_URL } from './shared/samla_constants';


@Injectable()
export class SamlaService {
  baseUrl = `${SERVER_API_VERSION}/samla`;

  constructor(private http: HttpClient) {
  }

  getSamlaVersion(): Observable<string> {
    const url = `${this.baseUrl}/version`;

    return this.http.get(url, {responseType: 'text'});
  }

  openAccessAppLocation() {
    window.open(ACCESS_APP_URL, "_blank");
  }
}
