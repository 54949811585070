import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { metadataApiUrl, SERVER_API_VERSION } from '../shared/samla_constants';
import { EventResponse, SlapiEventResponse } from './model/apiResponse.interface';
import { EventDomainStatus } from './model/event-domain-status.model';
import { EventFilter } from './model/event-filter.model';
import { Event } from './model/event.model';
import { Visibility } from './model/visibility.interface';


@Injectable()
export class EventService {
  visibilities: Visibility [];
  eventsUrl = `${SERVER_API_VERSION}/events`;
  metadataApiFilesUrl = `${metadataApiUrl}/files`;


  constructor(private httpClient: HttpClient) {}

  getEvents(page: number, limit: number, eventFilter: EventFilter): Observable<EventResponse> {
    const url = `${this.eventsUrl}?page=${page}&limit=${limit}`;
    const requestBody: string = this.getEventRequestBody(eventFilter);
    const params = new HttpParams().set('filter', requestBody);
    return this.httpClient.get<EventResponse>(url, { params: params });
  }

  getFilesFromMetadataApi(eventGuids: Array<String>): Observable<SlapiEventResponse> {
    const params = new HttpParams().set('events', eventGuids.join(','));
    return this.httpClient.get<SlapiEventResponse>(this.metadataApiFilesUrl, { params: params })
  }

  private getEventRequestBody(eventFilter: EventFilter): string {
    if (eventFilter === null) {
      return null;
    }
    return JSON.stringify({guid: eventFilter.guid,
                                startDate: eventFilter.startDate,
                                endDate: eventFilter.endDate,
                                description: eventFilter.description,
                                vehicles: eventFilter.vehicles,
                                tags: eventFilter.tags,
                                visibility: eventFilter.visibility,
                                collectionId: eventFilter.collectionId});
  }

  checkUploadStatus(pendingEventIds: number[]) {
    const url = `${this.eventsUrl}/status`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post<EventDomainStatus[]>(url,  JSON.stringify({pendingEventIds: pendingEventIds}), {headers: headers});
  }

  updateComment(id: number, comment: string): Observable<any> {
    const url = `${this.eventsUrl}/${id}/comment`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(url, JSON.stringify({comment: comment}), {headers: headers});
  }

  updateDescription(id: number, description: string): Observable<any> {
    const url = `${this.eventsUrl}/${id}/description`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(url, JSON.stringify({description: description}), {headers: headers});
  }

  tagEvent(eventId: number, tagIds: number[]): Observable<any> {
    const url = `${this.eventsUrl}/${eventId}/tags`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .post(url, JSON.stringify({tagIds: tagIds}), {headers: headers});
  }

  untagEvent(eventId: number, tagId: number): Observable<any> {
    const url = `${this.eventsUrl}/${eventId}/tags/${tagId}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .delete(url, {headers: headers});
  }

  tagMultipleEvents(tagIds: number[], eventsId: number[]): Observable<any> {
    const url = `${this.eventsUrl}/tags`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .post(url, JSON.stringify({eventsId: eventsId, tagIds: tagIds}),
             {headers: headers});
  }

  getEventVisibilities(): Observable<Visibility[]> {
    const url = `${this.eventsUrl}/visibilities`;
    return this.httpClient.get<Visibility[]>(url);
  }

  changeEventVisibility(eventId: number, visibilityId: number): any {
    const url = `${this.eventsUrl}/${eventId}/visibility/${visibilityId}`;
    return this.httpClient.put(url, null);
  }

  changeVisibilityMultipleEvents(visibilityId: number, eventsId: number[]): any {
    const url = `${this.eventsUrl}/visibilities`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(url, JSON.stringify({eventIds: eventsId, visibilityId: visibilityId}), {headers: headers});
  }

  getPendingEvents(events: Event[]): number[] {
    const pendingEventsIds: number[] = [];
    events.forEach(event => {
      if (this.isAtLeastOneProcessedFileMissing(event)) {
        pendingEventsIds.push(event.id);
      }
    });
    return pendingEventsIds;
  }

  private isAtLeastOneProcessedFileMissing(event: Event): boolean {
      return event.domains.some(domain => !domain.processedFileAvailable);
  }

  updateEventDomainStatus(eventDomainsStatus: EventDomainStatus[], events: Event[]) {
    eventDomainsStatus.forEach(status => {
      const index = events.findIndex(x => x.id === status.eventId);
      const event: Event = events[index];
      event.domains = status.domains;
    });
  }
}
