import { Component, ElementRef, Input, OnInit } from '@angular/core';

import { EventDomain } from 'app/event/model/event-domain.model';
import { ProcessedFileService } from '../../../../processed_file/processed-file.service';
import { Event } from './../../../model/event.model';

@Component({
  selector: 'app-event-download-popup',
  templateUrl: './event-download-popup.component.html',
  styleUrls: ['./event-download-popup.component.scss']
})

export class EventDownloadPopupComponent implements OnInit {
  @Input() event: Event;
  @Input() downloadPopup;
  private element: any;

  constructor(private processedFileService: ProcessedFileService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    document.body.appendChild(this.element)

    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'event-download-popup-modal') {
        this.closeModal();
      }
    });
  }

  getReadyDomains(): EventDomain[] {
    const readyDomains = this.event.domains.filter(domain => domain.processedFileAvailable);
    const clonedReadyDomains = readyDomains.map(domain => {
      const clonedDomain = Object.assign({}, domain);
      clonedDomain.name += clonedDomain.fileName.slice(clonedDomain.fileName.indexOf('.'));
      return clonedDomain;
    });

    clonedReadyDomains.sort((a, b) => a.name.localeCompare(b.name));

    return clonedReadyDomains;
  }

  downloadFile(fileName: string) {
    this.processedFileService.download(fileName);
    let domainName = this.event.domains.find(domain => domain.fileName === fileName).name;
    domainName += fileName.slice(fileName.indexOf('.'));
    (<any>window).plausible('Download Log', {props: {domain: domainName}});
  }

  closeModal() {
    this.downloadPopup.isOpen = false;
  }
}
