import { EventTag } from '../../shared/event_tag.model';
import { EventDomain } from './event-domain.model';

export class Event {

  public id: number;
  public guid: string;
  public startDateTime: Date;
  public endDatetime: Date;
  public triggerDateTime: Date;
  public driverName: string;
  public description: string;
  public comment: string;
  public vehicle: string;
  public length: number;
  public triggerLatitude: number;
  public triggerLongitude: number;
  public visibility: string;
  public tags: EventTag[];
  public domains: EventDomain[];
  public version: string;

  public isSelected = false;
  public isChecked = false;

  constructor(id: number,
              guid: string,
              startingDatetime: Date,
              endDateTime: Date,
              triggerDateTime: Date,
              driverName: string,
              description: string,
              comment: string,
              tags: EventTag[],
              vehicle: string,
              length: number,
              version: string,
              visibility: string,
              domains: EventDomain[]) {
    this.id = id;
    this.guid = guid;
    this.startDateTime = startingDatetime;
    this.endDatetime = endDateTime;
    this.triggerDateTime = triggerDateTime;
    this.driverName = driverName;
    this.description = description;
    this.comment = comment;
    this.tags = tags;
    this.vehicle = vehicle;
    this.length = length;
    this.version = version;
    this.visibility = visibility;
    this.domains = domains;
  }
}
