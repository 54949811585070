import { Component, Input, OnInit } from '@angular/core';

import {
  EventStatusService,
  StatusResponse,
} from '../event-status/event-status.service';

import { STRADA_LIBRARY_URL } from './../../shared/samla_constants';

@Component({
  selector: 'app-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.scss'],
})
export class EventStatusComponent implements OnInit {
  @Input() eventGuid: string;
  statusResponse: StatusResponse;
  stradaRedirect = false;
  errorLog: string;

  constructor(private statusService: EventStatusService) {}

  ngOnInit() {
    this.getEventStatus();
  }

  getEventStatus() {
    this.statusService.getEventStatus(this.eventGuid).subscribe(
      (response: StatusResponse) => {
        this.statusResponse = response;
        this.setIngestionStatus();
      },
      (error) => {
        this.errorLog = 'Error in fetching Strada status for '
        console.error(
          this.errorLog +
            this.eventGuid +
            ' - ' +
            error
        );
      }
    );
  }

  private setIngestionStatus() {
    switch (this.statusResponse.strada_status) {
      case 5:
        this.stradaRedirect = true;
        break;
      case 6:
        this.stradaRedirect = true;
        break;
      case 7:
        this.stradaRedirect = true;
        break;
      case 8:
        this.stradaRedirect = true;
        break;
      default:
        this.stradaRedirect = false;
    }
  }

  openStradaPlaybackWindow($event: MouseEvent) {
    const url = `${STRADA_LIBRARY_URL}${this.statusResponse.event_id}`;
    (<any>window).plausible('Strada playback redirect');
    window.open(url, '_blank');
  }
}
