import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-share-log',
  templateUrl: './share-log.component.html',
  styleUrls: ['./share-log.component.scss'],
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('250ms',
          style({ opacity: 1 })
        )
      ]),
      transition('* => void', [
        animate('500ms',
          style({ opacity: 0 })
        )
      ])
    ])
  ]
})
export class ShareLogComponent implements OnInit {
  @Input() guid: string;
  @Input() type: string;

  isToasting = false

  constructor() { }

  ngOnInit() { }

  onCreatAndCopyShareableLink() {
    const link = this.createShareableLink();
    console.log('shareable link: ' + link);
    this.copyToClipboard(link);
    this.isToasting = true;
    this.stopToastingSoon();
    return link;
  }

  createShareableLink(): string {
    return `${environment.url}${this.type}/${this.guid}`;
  }

  copyToClipboard(text: string): void {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  private stopToastingSoon(): void {
    setTimeout(() => {
      this.isToasting = false;
    }, 2000);
  }
}
