export class Collection {

  public id: number;
  public description: string;
  public createdDateTime: Date;
  public startDateTime: Date;
  public endDateTime: Date;
  public duration: number;
  public vehicle: string;
  public driverName: string;
  public type: string;
  public eventsCount: number;

  public isSelected = false;

  constructor( id: number, startDateTime: Date, stopDateTime: Date, createDateTime: Date,
               description: string, vehicle: string, driverName: string, type: string, duration: number, eventsCount: number) {
    this.id = id;
    this.startDateTime = startDateTime;
    this.endDateTime = stopDateTime;
    this.createdDateTime = createDateTime;
    this.description = description;
    this.vehicle = vehicle;
    this.eventsCount = eventsCount;
    this.driverName = driverName;
    this.type = type;
    this.duration = duration;
  }
}
