import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Visibility } from '../model/visibility.interface';
import { EventCardToolbarEnum } from '../model/event-card-toolbarOption.enum';

@Component({
  selector: 'app-event-toolbar',
  templateUrl: './event-toolbar.component.html',
  styleUrls: ['./event-toolbar.component.scss']
})
export class EventToolbarComponent implements OnInit {

  @Input() areEventsSelected: boolean;
  @Input() toolbarOptions: EventCardToolbarEnum[];
  @Output() goFilter = new EventEmitter<boolean>();
  @Output() goRefresh = new EventEmitter<boolean>();
  @Output() goTag = new EventEmitter<boolean>();
  @Output() goChangeVisibility = new EventEmitter<Visibility>();

  constructor() { }

  ngOnInit() {}

  isOptionIncluded(option: number): boolean {
    return this.toolbarOptions.includes(option);
  }

  onChangeVisibility(visibility: Visibility) {
    this.goChangeVisibility.emit(visibility);
  }

  onTag(): void {
    this.goTag.emit(true);
  }

  onRefresh(): void {
    this.goRefresh.emit(true);
  }

  onFilter(): void {
    this.goFilter.emit(true);
  }
}
