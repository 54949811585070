import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {DomainService} from '../domain.service';
import {Domain} from '../domain.model';
import {DOMAIN_DESCRIPTION_LENGTH} from 'app/shared/samla_constants';

@Component({
  selector: 'app-domain-edit-form',
  templateUrl: './domain-edit-form.component.html',
  styleUrls: ['./domain-edit-form.component.scss']
})
export class DomainEditFormComponent implements OnInit {
  @Input() domain: Domain;
  @Output() submitFinished = new EventEmitter<boolean>();

  dropdownSettings: any = {};
  loading = false;

  domainForm: FormGroup;
  submitting = false;
  serverErrorOnSubmitting = false;
  serverErrorMessage = '';

  constructor(private domainService: DomainService) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.domainForm = new FormGroup({
        'fullname': new FormControl({value: this.domain.fullName, disabled: true}),
        'description': new FormControl(this.domain.description, Validators.maxLength(DOMAIN_DESCRIPTION_LENGTH))
    });
  }

  onSubmit() {
    this.submitting = true;
    this.serverErrorMessage = '';
    this.serverErrorOnSubmitting = false;
    const description: string = this.domainForm.controls['description'].value;
    this.domainService.update(this.domain.id, description).
      subscribe(
        response => {
          if (response.status === 'SUCCESS') {
            // add locally
            this.domain.description = description;
            this.onCancel();
          }
        },
        (error) => {
          this.serverErrorOnSubmitting = true;
          this.submitting = false;
          this.serverErrorMessage = error;
        }
      );
  }

  onCancel() {
    this.submitting = false;
    this.submitFinished.emit(true);
  }
}
