import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import {Domain} from './domain.model';
import {SERVER_API_VERSION} from 'app/shared/samla_constants';

@Injectable()
export class DomainService {
  domainsUrl = `${SERVER_API_VERSION}/domains`;

  constructor(private httpClient: HttpClient) { }

  getDomains(): Observable<Domain[]> {
    const url = `${this.domainsUrl}/flattened`;
    return this.httpClient.get<Domain[]>(url);
  }

  update(id: number, description: string): Observable<any> {
    const url = `${this.domainsUrl}/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(url,
      JSON.stringify({ description: description}),
      {headers: headers});
  }
}
