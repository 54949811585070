import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EventService} from '../../event.service';
import {Event} from '../../model/event.model';
import {EVENT_DESCRIPTION_LENGTH} from '../../../shared/samla_constants';

@Component({
  selector: 'app-event-description',
  templateUrl: './event-description.component.html',
  styleUrls: ['./event-description.component.scss']
})
export class EventDescriptionComponent implements OnInit {
  @Input() event: Event;
  showForm = false;
  submitting = false;
  serverErrorOnSubmitting = false;
  serverErrorMessage = '';
  descriptionForm: FormGroup;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.initDescriptionForm();
  }
  private initDescriptionForm() {
    const description = this.event.description;
    this.descriptionForm = new FormGroup({
      'description': new FormControl(description, [Validators.required, Validators.maxLength(EVENT_DESCRIPTION_LENGTH)])
    });
  }
  onSubmitDescription(): void {
    this.submitting = true;
    this.serverErrorMessage = '';
    this.serverErrorOnSubmitting = false;
    const description = this.descriptionForm.value.description;
    this.eventService.updateDescription(this.event.id, description)
        .subscribe(
          resp => {
            if (resp.status === 'SUCCESS') {
              this.updateDescriptionLocally(this.event, description);
              this.submitting = false;
              this.onCancel();
            }
          },
          (error) => {
            this.serverErrorOnSubmitting = true;
            this.submitting = false;
            this.serverErrorMessage = error;
          }
        )
  }

  updateDescriptionLocally(event: Event, description: string) {
    event.description = description;
  }

  onCancel(): void {
    this.serverErrorOnSubmitting = false;
    this.showForm = false;
    this.descriptionForm.setValue({
      'description': this.event.description
    });
  }
}
