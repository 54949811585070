import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DEFAULT_ERROR_MESSAGE } from './shared/samla_constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
                .pipe (
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse.error instanceof ErrorEvent) {
                            // A client-side or network error occurred. Handle it accordingly.
                            console.error('An error occurred:', errorResponse.error.message);
                            return throwError(DEFAULT_ERROR_MESSAGE);

                        } else {
                            const transformedServerErrorMessage = this.transformServerError(req, errorResponse);
                            return throwError(transformedServerErrorMessage);
                       }
                    })
                )
    }

    private transformServerError(req: HttpRequest<any>, errorResponse: HttpErrorResponse): string {
        console.error( `Backend returned code ${errorResponse.status}, ` + `body was: ${errorResponse.error}`);
        if (errorResponse.status >= 400 && errorResponse.status < 500) {
            return errorResponse.error.message;
        }

        return DEFAULT_ERROR_MESSAGE;
    }
}
