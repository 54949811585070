import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-guid-column',
  templateUrl: './event-guid-column.component.html',
  styleUrls: ['./event-guid-column.component.scss']
})
export class EventGuidColumnComponent implements OnInit {

  @Input() eventGuid: string;
  isToasting = false;

  constructor() { }

  ngOnInit() {
  }

  onCreateAndCopyEventGuid() {
    console.log('event GUID: ' + this.eventGuid);
    this.copyToClipboard(this.eventGuid);
    this.isToasting = true;
    this.stopToastingSoon();
    return this.eventGuid;
  }

  copyToClipboard(text: string): void {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  private stopToastingSoon(): void {
    setTimeout(() => {
      this.isToasting = false;
    }, 2000);
  }
}
