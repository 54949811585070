import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { Vehicle } from './vehicle.model';
import { SERVER_API_VERSION } from 'app/shared/samla_constants';

@Injectable()
export class VehicleService {
  vehiclesUrl = `${SERVER_API_VERSION}/vehicles`;

  constructor(private httpClient: HttpClient) { }

  getVehicles(): Observable<Vehicle[]> {
    return this.httpClient.get<Vehicle[]>(this.vehiclesUrl);
  }
}
