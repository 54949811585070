import {Component, OnInit} from '@angular/core';

import {DomainService} from './domain.service';
import {Domain} from './domain.model';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {
  domains: Domain[];
  messageFromServer = '';
  loading = false;

  showDomainForm = false;
  showDomainAddForm = false;
  selectedDomain = null;

  constructor(private domainService: DomainService) { }

  ngOnInit() {
    this.getDomains();
  }

  getDomains() {
    this.loading = true;
    this.domainService.getDomains()
        .subscribe(
          (domains: Domain[]) => {
            if (domains.length === 0) {
              this.messageFromServer = 'No domains found!'
            }
            this.domains = domains;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  onShowMDomainEditForm(domain: Domain) {
    this.selectedDomain = domain;
    this.showDomainForm = true;
    window.scrollTo(0, 0);
  }

  onFinishEditSubmition() {
    this.showDomainForm = false;
    this.selectedDomain = null;
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }
}
