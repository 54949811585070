import {KeycloakConfig} from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  url: 'https://fg.ciam.preprod.aws.scania.com/auth',
  realm: 'scania',
  clientId: 'scanialogger-frontend'
};

export const environment = {
  production: false,
  devtest: true,
  mock: false,
  local: false,
  serverUrl: 'https://samla.logger.devtest.aws.scania.com',
  url: 'https://web.logger.devtest.aws.scania.com/',
  stradaIngestionApi: 'https://strada-ingestion.logger.devtest.aws.scania.com/api',
  stradaLibraryUrl: 'https://scania.applied.dev/strada/library?searchTerm=',
  keycloakConfig,
  metadataApiUrl: 'https://metadata.logger.devtest.aws.scania.com/api'
};
