import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Event} from './../../event/model/event.model';
import {EventService} from '../../event/event.service';
import {Visibility} from '../model/visibility.interface';
import {EventTag} from '../../shared/event_tag.model';
import {EventFilter} from '../../event/model/event-filter.model';
import {EventCardConfig} from '../model/event-card-config.interface';
import { SlapiEventResponse } from '../model/apiResponse.interface';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  @Input() events: Event[];
  @Input() total: number;
  @Input() totalTagsCount: number;
  @Input() loading: boolean;
  @Input() limit: number;
  @Input() serverGetEventsMessage: string;
  @Input() disableVehicleFilter: boolean;
  @Input() optionConfig: EventCardConfig;
  @Input() slapiResponse: SlapiEventResponse;

  @Output() goFilter = new EventEmitter<EventFilter>();
  @Output() goFilterByVisibility = new EventEmitter<number>();
  @Output() goRefresh = new EventEmitter<number>();
  @Output() goChangePage = new EventEmitter<number>();

  page = 1;

  showFilterEventForm = true;
  showTaggingEventsForm = false;
  successTagMessage = '';
  serverVisibilityErrorMessage = '';
  selectedEvents: Event[] = [];
  allowDownloadOption: true;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.selectedEvents = [];
  }

  // PAGINATION
  goToPage(n: number): void {
    this.page = n;
    this.goChangePage.emit(this.page);
  }

  onNext(): void {
    this.page++;
    this.goChangePage.emit(this.page);
  }

  onPrev(): void {
    this.page--;
    this.goChangePage.emit(this.page);
  }

  onRefresh(refresh: boolean) {
    this.page = 1;
    this.goRefresh.emit(this.page);
  }

  // QUICK FILTER
  onFilterByVisibility(visibility: number) {
    this.goFilterByVisibility.emit(visibility);
  }

  onSubmitFilter(filter: EventFilter): void {
    this.page = 1;
    this.goFilter.emit(filter);
  }

  onCancelFilter(): void {
    this.goFilter.emit(null);
  }

  // TAG FUNCTIONALITY
  onShowTagForm(showForm: boolean): void {
    if (showForm) {
      this.showTaggingEventsForm = true;
    }
  }

  onCancelTagEvents(): void {
    this.showTaggingEventsForm = false;
  }

  onTagEvents(tagsToAdd: EventTag[]): void {
    for (const event of this.selectedEvents) {
      const index = this.events.findIndex(e => e.id === event.id);
      const tags: EventTag[] = this.events[index].tags;
      this.addTagIfNotExist(tags, tagsToAdd);
    }

    this.showTaggingEventsForm = false;
    this.successTagMessage = 'Events Tagged successfuly';
    this.hideMessage('successTagMessage');
  }

  private addTagIfNotExist(currentTags: EventTag[], tagsToAdd: EventTag[]): void {
    let eTIndex = 0;
    for (const tagToAdd of tagsToAdd) {
      eTIndex = currentTags.findIndex(x => x.tag.id === tagToAdd.tag.id);
      if (eTIndex === -1) {
        currentTags.push(tagToAdd);
      }
    }
  }

  // VISIBILITY FUNCTIONALITY
  onChangeEventVisibility(visibility: Visibility): void {
    const eventsId = this.selectedEvents.map(event => event.id);
    this.eventService.changeVisibilityMultipleEvents(visibility.id, eventsId)
        .subscribe(resp => {
          if (resp.status === 'SUCCESS') {
            this.changeVisibilityLocally(visibility.name);
          }
        },
        (error) => {
          this.serverVisibilityErrorMessage = error;
          this.hideMessage('serverVisibilityErrorMessage');
        }
      )
  }

  changeVisibilityLocally(visibility: string): void {
    let index = 0;
    for (const event of this.selectedEvents) {
      index = this.events.findIndex(e => e.id === event.id);
      this.events[index].visibility = visibility;
    }
  }

  onSelectEvents(events: Event[]): void {
    this.selectedEvents = [];
    this.selectedEvents = [...events];
  }

  private hideMessage(messageToHide: string): void {
    setTimeout(() => {
      if (messageToHide === 'successTagMessage') {
        this.successTagMessage = '';
      } else if (messageToHide === 'serverVisibilityErrorMessage') {
        this.serverVisibilityErrorMessage = '';
      }
    }, 3000);
  }

}
