export class EventDomain {

  public id: number;
  public name: string;
  public description: string;
  public processedFileAvailable: boolean;
  public fileName: string;

  constructor(name: string, processedFileAvailable: boolean, id?: number, description?: string, fileName?: string) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.processedFileAvailable = processedFileAvailable;
    this.fileName = fileName;
  }
}
