import { Event } from './../../model/event.model';

import { Component, Input, OnInit } from '@angular/core';
import { EventDomain } from '../../model/event-domain.model';

@Component({
  selector: 'app-event-domain',
  templateUrl: './event-domain.component.html',
  styleUrls: ['./event-domain.component.scss']
})
export class EventDomainComponent implements OnInit {
  @Input() event: Event;

  constructor() { }

  ngOnInit() {}

  getStatusColor(domain: EventDomain): string {
    if (domain.processedFileAvailable) {
      return 'badge-success';
    }
    return 'badge-secondary';
  }

  getDomainDescription(domain: EventDomain): string {
    if (domain.description === null || domain.description === '') {
      return 'Not specified';
    }
    return domain.description;
  }

  getUniqueDomains(event: Event) {
    const domainNames = new Set();
    const domainList = [];

    for (const domain of event.domains) {
      if (!domainNames.has(domain.name)) {
        domainNames.add(domain.name);
        domainList.push(domain);
      }
    }
    return domainList;
  }

}
