
import { SamlaService } from '../samla.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-samla-desktop',
  templateUrl: './samla-desktop.component.html'
})
export class SamlaDesktopComponent implements OnInit {

  ngOnInit() {
  }

  constructor(private samlaService: SamlaService) {
  }

  onGetDesktopVersion() {
    (<any>window).plausible('Download Desktop Application');
    this.samlaService.openAccessAppLocation();
  }
}
