import { Event } from './../../model/event.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { EventService } from '../../event.service';
import { Visibility } from '../../model/visibility.interface';

@Component({
  selector: 'app-event-visibility',
  templateUrl: './event-visibility.component.html',
  styleUrls: ['./event-visibility.component.scss']
})

export class EventVisibilityComponent implements OnInit {
  @Input() event: Event;
  @Output() filter = new EventEmitter<boolean>();

  visibilities: Visibility[];
  serverErrorOnSubmitting = false;
  serverErrorMessage = '';

  constructor(public eventService: EventService) { }

  ngOnInit() {
    this.eventService.getEventVisibilities()
                      .subscribe(visibilities => {
                        this.visibilities = visibilities;
                      });
  }

  onSelectVisibilityOption(visibility: Visibility, $event: MouseEvent) {
    $event.stopPropagation();
    this.eventService.changeEventVisibility(this.event.id, visibility.id)
                      .subscribe(
                        (response) => {
                          if (response.status === 'SUCCESS') {
                            this.event.visibility = visibility.name;
                          }
                        },
                        (error) => {
                          this.serverErrorOnSubmitting = true;
                          this.serverErrorMessage = error;
                          this.hideErrorMessage();
                        }
                      )
  }

  private hideErrorMessage(): void {
    setTimeout(() => {
      this.serverErrorOnSubmitting = false;
      this.serverErrorMessage = '';
    }, 3000);
  }

  getVisibilityIcon(visibility: string) {
    switch (visibility) {
      case 'Star' : return 'icon-star';
      case 'Lab' : return 'icon-chemistry';
      case 'Hidden' : return 'icon-frame';
      default: return 'fa fa-square-o'
    }
  }

  getVisibilityColor(visibility: string, active: boolean) {
    let className = 'btn';
    if (!active) {
      className = className + '-outline';
    }
    switch (visibility) {
      case 'Star' : return className + '-warning';
      case 'Lab' : return className + '-success';
      case 'Hidden' : return className + '-secondary';
      default: return className + '-primary';
    }
  }
}
