import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeunit'
})
export class TimeUnitPipe implements PipeTransform {

  transform(seconds: number): string {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    const hDisplay = h > 0 ? h + ' hr, ' : '';
    const mDisplay = m > 0 ? m + ' min, ' : '';
    const sDisplay = s + ' sec';
    return hDisplay + mDisplay + sDisplay;
  }
}
