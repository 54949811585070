import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Collection} from './../collection.model';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent implements OnInit {
  @Input() title: string;
  @Input() collections: Collection[];
  @Input() collectionTableColumns: string[];
  @Input() total: number;
  @Input() loading: boolean;
  @Input() limit: number;
  @Input() serverGetCollectionsMessage: string;

  @Output() goRefresh = new EventEmitter<number>();
  @Output() goChangePage = new EventEmitter<number>();

  page = 1;

  constructor() { }

  ngOnInit() {}

  // PAGINATION
  goToPage(n: number): void {
    this.page = n;
    this.goChangePage.emit(this.page);
  }

  onNext(): void {
    this.page++;
    this.goChangePage.emit(this.page);
  }

  onPrev(): void {
    this.page--;
    this.goChangePage.emit(this.page);
  }

  onRefresh(refresh: boolean) {
    this.page = 1;
    this.goRefresh.emit(this.page);
  }

}
