import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import { SERVER_API_VERSION, metadataApiUrl } from 'app/shared/samla_constants';

@Injectable()
export class ProcessedFileService {
  processedFilesUrl = `${SERVER_API_VERSION}/processed-files`;
  metadataApiPresignedUrl = `${metadataApiUrl}/presigned-url/processed`;

  constructor(private httpClient: HttpClient) {}

  getDetails(eventId: number, domainId: number): Observable<any> {
    const url = `${this.processedFilesUrl}/event/${eventId}/domain/${domainId}/details`;

    return this.httpClient.get(url);
  }

  getDetailsOldEventVersion(eventId: number): Observable<any> {
    const url = `${this.processedFilesUrl}/event/${eventId}/details`;

    return this.httpClient.get(url);
  }

  download(fileName: string) {
    this.httpClient.get<string>(`${this.metadataApiPresignedUrl}/${fileName}`).subscribe((response: any) => {
      const a = <HTMLAnchorElement>document.createElement('A');
      a.href = response.presignedUrl;
      a.download = response.presignedUrl.substr(response.presignedUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

}
