import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Event} from './../../event/model/event.model';
import {EventService} from '../../event/event.service';
import {EventFilter} from '../../event/model/event-filter.model';
import {EventResponse} from '../../event/model/apiResponse.interface';
import {TagService} from '../../tag/tag.service';
import {Tag} from '../../tag/tag.model';
import {EventCardConfig} from '../../event/model/event-card-config.interface';
import {EventCardToolbarEnum} from '../../event/model/event-card-toolbarOption.enum';
import {DEFAULT_PAGINATION_LIMIT, INITIAL_PAGE_NUMBER} from 'app/shared/samla_constants';

@Component({
  selector: 'app-events-vehicles',
  templateUrl: './events-vehicles.component.html'
})
export class EventsVehiclesComponent implements OnInit {
  vehicleId: number;
  vehicleName: string;

  events: Event[];

  optionConfig: EventCardConfig = { showQuickFilter: false,
                                    toolbarOptions: [EventCardToolbarEnum.Refresh, EventCardToolbarEnum.Visibility,
                                      EventCardToolbarEnum.Tag, EventCardToolbarEnum.Filter]
                                  };
  loading: boolean;
  eventFilter: EventFilter;
  total = 0;
  totalTagsCount: number;
  messageFromServer = '';
  limit = DEFAULT_PAGINATION_LIMIT;

  keepAliveCheckLogReadyTimerSubscription = true;

  constructor(private eventService: EventService, private tagService: TagService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.vehicleId = +this.route.snapshot.params['id'];
    this.vehicleName = this.route.snapshot.params['name'];
    this.eventFilter = new EventFilter(null, null, null, null, [this.vehicleId], null, null, null);
    this.tagService.getTags()
        .subscribe(
          (tags: Tag[]) => {
            this.totalTagsCount = tags.length;
            this.getEvents(INITIAL_PAGE_NUMBER);
          },
          error => {
            this.notifyRequestError(error);
          }
        );
  }

  getEvents(page: number) {
    this.loading = true;
    this.messageFromServer = '';
    this.eventService.getEvents(page, DEFAULT_PAGINATION_LIMIT, this.eventFilter)
        .subscribe(
          (eventResponse: EventResponse) => {
            if (eventResponse.results.length === 0) {
              this.messageFromServer = 'No events found!';
            }
            this.events = eventResponse.results;
            this.total = eventResponse.total;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  onFilter(filter: EventFilter) {
    if (filter !== null) {
      this.eventFilter.guid = filter.guid;
      this.eventFilter.startDate = filter.startDate;
      this.eventFilter.endDate = filter.endDate;
      this.eventFilter.description = filter.description;
      this.eventFilter.tags = filter.tags;
    } else {
      this.eventFilter.guid = null;
      this.eventFilter.startDate = null;
      this.eventFilter.endDate = null;
      this.eventFilter.description = null;
      this.eventFilter.tags = null;
    }
    this.getEvents(INITIAL_PAGE_NUMBER);
  }

  onFilterByVisibility(visibility: number) {
    this.eventFilter.visibility = visibility;
    this.getEvents(INITIAL_PAGE_NUMBER);
  }

  onChangePage(page: number) {
    this.getEvents(page);
  }

  onRefresh(page: number) {
    this.getEvents(page);
  }

  OnDestroy() {
    this.keepAliveCheckLogReadyTimerSubscription = false;
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }
}
