export class EventFilter {
    guid: string;
    startDate: string;
    endDate: string;
    description: string;
    vehicles: number[];
    tags: number[];
    visibility: number;
    collectionId: number;

    constructor(guid: string, startDate: string, endDate: string, description: string, vehicles: number[],
        tags: number[], visibility: number, collectionId: number) {
        this.guid = guid;
        this.startDate = startDate;
        this.endDate = endDate;
        this.description = description;
        this.vehicles = vehicles;
        this.tags = tags;
        this.visibility = visibility;
        this.collectionId = collectionId;
    }

    toString(): string {
        return 'guid: ' + this.guid + ' ' +
            'startDate: ' + this.startDate + ' ' +
            'endDate: ' + this.endDate + ' ' +
            'description: ' + this.description + ' ' +
            'vehicles: ' + this.vehicles.length + ' ' +
            'tags: ' + this.tags + ' ' +
            'visibility: ' + this.visibility +
            'collectionId: ' + this.collectionId;
    }
}
