import {Component, Input, OnInit} from '@angular/core';

import {Collection} from './../../collection.model';
import {EventFilter} from '../../../event/model/event-filter.model';
import {EventService} from '../../../event/event.service';
import {TagService} from '../../../tag/tag.service';
import {Tag} from '../../../tag/tag.model';
import {EventResponse} from '../../../event/model/apiResponse.interface';
import {Event} from './../../../event/model/event.model';
import {EventCardConfig} from '../../../event/model/event-card-config.interface';
import {EventCardToolbarEnum} from '../../../event/model/event-card-toolbarOption.enum';
import {DEFAULT_PAGINATION_LIMIT, INITIAL_PAGE_NUMBER} from 'app/shared/samla_constants';

@Component({
  selector: 'app-events-collection',
  templateUrl: './events-collection.component.html',
  styleUrls: ['./events-collection.component.scss']
})
export class EventsCollectionComponent implements OnInit {
  @Input() collection: Collection;

  events: Event[];
  totalEvents: number;

  optionConfig: EventCardConfig = {showQuickFilter: false, toolbarOptions: [EventCardToolbarEnum.Refresh]}
  loading: boolean;
  eventFilter: EventFilter;

  totalTagsCount: number;
  messageFromServer = '';
  limit = DEFAULT_PAGINATION_LIMIT;

  keepAliveCheckLogReadyTimerSubscription = true;

  constructor(private eventService: EventService, private tagService: TagService) { }

  ngOnInit() {
    this.loading = true;
    this.eventFilter = new EventFilter(null, null, null, null, null, null, null, this.collection.id);
    this.tagService.getTags()
        .subscribe(
          (tags: Tag[]) => {
            this.totalTagsCount = tags.length;
            this.getEvents(INITIAL_PAGE_NUMBER);
          },
          error => {
            this.notifyRequestError(error);
          }
        );
  }

  getEvents(page: number) {
    this.loading = true;
    this.messageFromServer = '';
    this.eventService.getEvents(page, DEFAULT_PAGINATION_LIMIT, this.eventFilter)
        .subscribe(
          (eventResponse: EventResponse) => {
            if (eventResponse.results.length === 0) {
              this.messageFromServer = 'No events found!';
            }
            this.events = eventResponse.results;
            this.totalEvents = eventResponse.total;
            this.loading = false;
          },
          (error => {
            this.notifyRequestError(error);
          })
        )
  }

  onChangePage(page: number) {
    this.getEvents(page);
  }

  onRefresh(page: number) {
    this.getEvents(page);
  }

  OnDestroy() {
    this.keepAliveCheckLogReadyTimerSubscription = false;
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }


}
