import { Component, OnInit, Input } from '@angular/core';

import { Event } from '../model/event.model';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  @Input() event: Event;
  @Input() tagListLength: number;

  constructor() { }

  ngOnInit() {}
}
