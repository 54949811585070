import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { EventService } from '../../event.service';
import { Visibility } from '../../model/visibility.interface';

@Component({
  selector: 'app-visibility-toolbar-button',
  templateUrl: './visibility-toolbar-button.component.html',
  styleUrls: ['./visibility-toolbar-button.component.scss']
})
export class VisibilityToolbarButtonComponent implements OnInit {
  @Input() areEventsSelected: boolean;
  @Output() goChangeVisibility = new EventEmitter<Visibility>();

  visibilities: Visibility[];

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.eventService.getEventVisibilities()
        .subscribe(
          (visibilities: Visibility[]) => {
            this.visibilities = visibilities;
          },
          (error => {
            // TODO: error handling
          })
        );
   }

  getVisibilityIcon(visibility: string) {
    switch (visibility) {
      case 'Star' : return 'icon-star';
      case 'Lab' : return 'icon-chemistry';
      case 'Hidden' : return 'icon-frame';
      default: return 'fa fa-square-o';
    }
  }

  onChangeVisibility(visibility: Visibility) {
    this.goChangeVisibility.emit(visibility);
  }
}
