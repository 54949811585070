import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import {CollectionResponse} from './collectionResponse.interface';
import { SERVER_API_VERSION } from 'app/shared/samla_constants';


@Injectable()
export class CollectionService {
  collectionsUrl = `${SERVER_API_VERSION}/events/collections`;

  constructor(private httpClient: HttpClient) {}

  getSystemCollections(page: number, limit: number): Observable<CollectionResponse> {
    const url = `${this.collectionsUrl}?page=${page}&limit=${limit}&type=SYSTEM`;
    return this.httpClient.get<CollectionResponse>(url);
  }

  getTranferCollections(page: number, limit: number): Observable<CollectionResponse> {
    const url = `${this.collectionsUrl}?page=${page}&limit=${limit}&type=TRANSFER`;
    return this.httpClient.get<CollectionResponse>(url);
  }

}
