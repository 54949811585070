import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { STRADA_INGESTION_API } from './../../shared/samla_constants';

@Injectable()
export class EventStatusService {
  constructor(private httpClient: HttpClient) {}

  getEventStatus(eventId: string): Observable<StatusResponse> {
    const url = `${STRADA_INGESTION_API}/status/${eventId}`;
    return this.httpClient.get<StatusResponse>(url);
  }
}

export interface StatusResponse {
  event_id: string;
  event_synced_on: Date;
  strada_status: number;
  strada_status_changed_on: Date;
  strada_ingestion_id: string;
  strada_simulation_run_status: number;
}
