import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import 'rxjs/add/operator/toPromise';

import { TagService } from '../tag.service';
import { Tag } from '../tag.model';


@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.scss']
})
export class TagEditComponent implements OnInit {

  @Input() tag: Tag = null;
  @Output() submitted = new EventEmitter<boolean>();

  tagForm: FormGroup;
  submitting = false;
  serverErrorMessage = '';

  constructor(private tagService: TagService) { }

  ngOnInit() {
    this.initForm();
  }

  onCancel() {
    this.submitted.emit(true);
  }

  onSubmit() {
    this.submitting = true;
    const newTagName: string = this.tagForm.controls['name'].value;
    let serverResponse;

    if (this.tag != null) {
      serverResponse = this.tagService.update(this.tag.id, newTagName);
    } else {
      serverResponse = this.tagService.create(newTagName.split(','));
    }

    serverResponse
      .subscribe(
        (response) => {
          if (response.status === 'SUCCESS') {
            this.submitting = false;
            this.onCancel();
          }
        },
        (error) => {
          this.submitting = false;
          this.serverErrorMessage = error;
        }
      );
  }

  private initForm() {
    let tagName = '';
    if (this.tag != null) {
      tagName = this.tag.name;
    }
    this.tagForm = new FormGroup({
      'name': new FormControl(tagName, Validators.required)
    });
  }
}
