import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import {
  DEFAULT_PAGINATION_LIMIT,
  INITIAL_PAGE_NUMBER
} from 'app/shared/samla_constants';
import { switchMap } from 'rxjs/operators';
import { Tag } from '../tag/tag.model';
import { TagService } from './../tag/tag.service';
import { EventService } from './event.service';
import { EventResponse, SlapiEventResponse } from './model/apiResponse.interface';
import { EventCardConfig } from './model/event-card-config.interface';
import { EventCardToolbarEnum } from './model/event-card-toolbarOption.enum';
import { EventDomain } from './model/event-domain.model';
import { EventFilter } from './model/event-filter.model';
import { Event } from './model/event.model';

type SlapiEventDomainMap = Map<string, Map<string, string[]>>;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
})
export class EventComponent implements OnInit, OnDestroy {
  events: Event[];
  samlaEvents: Event[];
  slapiEventResponse: SlapiEventResponse;

  optionConfig: EventCardConfig = {
    showQuickFilter: true,
    toolbarOptions: [
      EventCardToolbarEnum.Refresh,
      EventCardToolbarEnum.Visibility,
      EventCardToolbarEnum.Tag,
      EventCardToolbarEnum.Filter,
    ],
  };
  loading: boolean;
  eventFilter: EventFilter;
  total = 0;
  totalTagsCount: number;
  messageFromServer = '';
  limit = DEFAULT_PAGINATION_LIMIT;

  keepAliveCheckLogReadyTimerSubscription = true;

  constructor(
    private eventService: EventService,
    private tagService: TagService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;
    const guid = this.route.snapshot.params['guid'];
    if (guid) {
      this.eventFilter = new EventFilter(
        guid,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } else {
      this.eventFilter = new EventFilter(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }

    this.tagService.getTags().subscribe(
      (tags: Tag[]) => {
        this.totalTagsCount = tags.length;
        this.getEvents(INITIAL_PAGE_NUMBER);
      },
      (error) => {
        this.notifyRequestError(error);
      }
    );
  }

  // Retrieve events from server and update event state
  getEvents(page: number) {
    this.loading = true;
    this.messageFromServer = '';
    let samlaEventResponse: EventResponse;

    this.eventService.getEvents(page, DEFAULT_PAGINATION_LIMIT, this.eventFilter)
      .pipe(
        switchMap((eventResponse: EventResponse) => {
          if (eventResponse.results.length === 0) {
            this.messageFromServer = 'No events found!';
          }

          this.samlaEvents = eventResponse.results;
          samlaEventResponse = eventResponse;

          for (const event of this.samlaEvents) {
            for (const domain of event.domains) { // Make all domains unavailable
              domain.processedFileAvailable = false;
            }
          }

          // Fetch events from SLAPI
          const eventGuids = this.samlaEvents.map((event) => event.guid);
          return this.eventService.getFilesFromMetadataApi(eventGuids);
        })
      )
      .subscribe(
        (slapiResponse: SlapiEventResponse) => {
          const slapiEventFilesMap: SlapiEventDomainMap = this.slapiResponseToGuidMap(slapiResponse);
          this.events = this.mergeEventDomains(this.samlaEvents, slapiEventFilesMap);
          this.total = samlaEventResponse.total;
          this.loading = false;
        },
        ((error) => {
          this.notifyRequestError(error);
        })
      )
  }

  mergeEventDomains(samlaEvents: Event[], slapiEventDomainMap: SlapiEventDomainMap): Event[] {
    const events: Event[] = []

    for (const event of samlaEvents) {
      if (!slapiEventDomainMap.has(event.guid)) { // If an event did not exist in SLAPI, just add it and continue
        events.push(event);
        continue;
      }

      const domains: EventDomain[] = [];
      const samlaDomains = new Set(event.domains.map(domain => domain.name));
      const slapiDomains = new Set(slapiEventDomainMap.get(event.guid).keys());
      const uniqueSlapiDomains = new Set(Array.from(slapiDomains.values()).filter((domain: string) => !samlaDomains.has(domain)));

      event.domains.forEach((domain) => { // For each SAMLA event domain
        if (slapiDomains.has(domain.name)) {
          const files = slapiEventDomainMap.get(event.guid).get(domain.name);
          files.forEach((fileName) => {
            domains.push(new EventDomain(domain.name, true, domain.id, domain.description, fileName));
          })
        } else {
          domains.push(domain);
        }
      });

      uniqueSlapiDomains.forEach((_key, val, _set) => { // For each SLAPI event not in SAMLA
        const files = slapiEventDomainMap.get(event.guid).get(val);
        files.forEach((fileName) => {
          domains.push(new EventDomain(val, true, undefined, undefined, fileName));
        });
      });
      event.domains = domains;
      events.push(event);
    }
    return events;
  }

  slapiResponseToGuidMap(slapiEventResponse: SlapiEventResponse): SlapiEventDomainMap {
    const events = slapiEventResponse.reduce((accumulator, current) => {
      const guid = current.event.guid;
      if (accumulator.has(guid)) {
        const files = accumulator.get(guid).get(current.domain.name) || [];
        files.push(current.name);
        accumulator.get(guid).set(current.domain.name, files);
      } else {
        accumulator.set(guid, new Map([[current.domain.name, [current.name]]]));
      }
      return accumulator;
    }, new Map());
    return events;
  }

  onFilter(filter: EventFilter) {
    if (filter !== null) {
      this.eventFilter.guid = filter.guid;
      this.eventFilter.startDate = filter.startDate;
      this.eventFilter.endDate = filter.endDate;
      this.eventFilter.vehicles = filter.vehicles;
      this.eventFilter.description = filter.description;
      this.eventFilter.tags = filter.tags;
    } else {
      this.eventFilter.guid = null;
      this.eventFilter.startDate = null;
      this.eventFilter.endDate = null;
      this.eventFilter.description = null;
      this.eventFilter.vehicles = null;
      this.eventFilter.tags = null;
    }
    this.getEvents(INITIAL_PAGE_NUMBER);
  }

  onFilterByVisibility(visibility: number) {
    this.eventFilter.visibility = visibility;
    this.getEvents(INITIAL_PAGE_NUMBER);
  }

  onChangePage(page: number) {
    this.getEvents(page);
  }

  onRefresh(page: number) {
    this.getEvents(page);
  }

  ngOnDestroy() {
    this.keepAliveCheckLogReadyTimerSubscription = false;
  }

  private notifyRequestError(error) {
    this.loading = false;
    this.messageFromServer = error;
  }
}
