import { Tag } from '../tag/tag.model';

export class EventTag {

  public tag: Tag;

  constructor( tag: Tag) {
    this.tag = tag;
  }
}
