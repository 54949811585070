import { Component, Input, OnInit } from '@angular/core';

import { Event } from './../../model/event.model';

@Component({
  selector: 'app-event-download',
  templateUrl: './event-download.component.html',
  styleUrls: ['./event-download.component.scss']
})

export class EventDownloadComponent implements OnInit {
  @Input() event: Event;
  downloadPopup = {'isOpen': false};

  constructor() {}

  ngOnInit() {}

  isAtleastProcessedFileAvailable(): boolean {
    return this.event.domains.some(domain => domain.processedFileAvailable);
  }

  openDownloadPopup() {
    this.downloadPopup.isOpen = true;
  }
}
