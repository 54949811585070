import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Tag } from '../../tag/tag.model';
import { EventTag } from './../../shared/event_tag.model';
import { TagService } from './../../tag/tag.service';
import { EventService } from './../event.service';
import { Event } from './../model/event.model';

@Component({
  selector: 'app-tagging-multiple-events',
  templateUrl: './tagging-multiple-events.component.html',
  styleUrls: ['./tagging-multiple-events.component.scss']
})
export class TaggingMultipleEventsComponent implements OnInit {
  @Input() events: Event[];
  @Output() goCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() goSubmit: EventEmitter<EventTag[]> = new EventEmitter<EventTag[]>();

  taggingMultipleEventsForm: FormGroup;
  tags: Tag[] = [];

  loading = false;
  submitting = false;
  serverErrorOnSubmitting = false;
  showSelectedEventsDetail = false;
  serverErrorMessage = '';

  dropdownSettings: any = {};

  constructor(public eventService: EventService, public tagService: TagService) { }

  ngOnInit() {
    this.loading = true;
    this.tagService.getTags()
      .subscribe(resp => {
        this.tags = resp;
        this.initForm();
        this.loading = false;
      });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  private initForm() {
    const tags = [];
    this.taggingMultipleEventsForm = new FormGroup({
        'tags': new FormControl(tags, Validators.required)
    });
  }

  onSubmit() {
    this.submitting = true;
    this.serverErrorMessage = '';
    this.serverErrorOnSubmitting = false;

    const tagIds: number[] = this.taggingMultipleEventsForm.controls['tags'].value;
    const tags: EventTag[] = this.getTagsByIds(tagIds);
    const eventsId = this.events.map(event => event.id);

    this.eventService.tagMultipleEvents(tagIds, eventsId).
      subscribe(
        response => {
          if (response.status === 'SUCCESS') {
            this.goSubmit.emit(tags);
          }
        },
        (error) => {
          this.serverErrorOnSubmitting = true;
          this.submitting = false;
          this.serverErrorMessage = error;
        }
      );
  }

  private getTagsByIds(tagIds: number[]): EventTag[] {
    const eventTags: EventTag[] = [];
    for (const tag of this.tags) {
      for (const id of tagIds) {
        if (tag.id === id) {
          eventTags.push(new EventTag(tag));
        }
      }
    }
    return eventTags;
  }

  onCancel() {
    this.submitting = false;
    this.goCancel.emit(null);
  }
}
