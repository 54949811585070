import { environment } from '../../environments/environment';

export const serverUrl = environment.serverUrl;
export const metadataApiUrl = environment.metadataApiUrl;

export const EVENT_DESCRIPTION_LENGTH = 5000;
export const EVENT_COMMENT_LENGTH = 2000;
export const DOMAIN_DESCRIPTION_LENGTH = 160;
export const START_DATE_SESSION_TABLE_COLUMN = 'StartDate';
export const STOP_DATE_SESSION_TABLE_COLUMN = 'StopDate';
export const CREATED_DATE_SESSION_TABLE_COLUMN = 'Uploaded';
export const DURATION_SESSION_TABLE_COLUMN = 'Duration';
export const DESCRIPTION_SESSION_TABLE_COLUMN = 'Description';
export const VEHICLE_SESSION_TABLE_COLUMN = 'Vehicle';
export const DRIVER_SESSION_TABLE_COLUMN = 'Driver';
export const EVENTS_SESSION_TABLE_COLUMN = 'Events';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Wait a few minutes and try again by refreshing the page!';
export const CHECK_UPLOAD_STATUS_INTERVAL = 120000;
export const INITIATE_CHECK_UPLOAD_STATUS_INTERVAL = 5000;
export const CHECK_DOWNLOAD_PROGRESS_INTERVAL = 1000;
export const INITIATE_CHECK_DOWNLOAD_PROGRESS_INTERVAL = 2000;
export const DEFAULT_PAGINATION_LIMIT = 50;
export const INITIAL_PAGE_NUMBER = 1;
export const SERVER_API_VERSION = `${serverUrl}/api/v2`;
export const ACCESS_APP_URL = 'https://scaniaazureservices.sharepoint.com/:f:/r/sites/ECPlatform/Shared%20Documents/Scania%20Logger/SAMLA%20Access%20App/latest';

export const STRADA_INGESTION_API = environment.stradaIngestionApi;
export const STRADA_LIBRARY_URL = environment.stradaLibraryUrl;
