import {Collection} from './../collection.model';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-collection-table',
  templateUrl: './collection-table.component.html',
  styleUrls: ['./collection-table.component.scss']
})
export class CollectionTableComponent implements OnInit {
  @Input() collections: Collection[];
  @Input() columns: string[];
  @Input() loading: boolean;
  @Input() serverGetCollectionsMessage = '';

  selectedColumns = 9;

  constructor() { }

  ngOnInit() {}

  onSelectCollection(collection: Collection) {
    collection.isSelected = !collection.isSelected;
  }

  stopEventPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }
}
