import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Vehicle} from '../../vehicles/vehicle.model';
import {EventFilter} from '../model/event-filter.model';

import {Tag} from '../../tag/tag.model';
import {TagService} from './../../tag/tag.service';
import {VehicleService} from 'app/vehicles/vehicle.service';

import {IMyDateRangeModel, IMyDrpOptions} from 'mydaterangepicker';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit {
  vehicles: Vehicle[] = [];
  tags: Tag[] = [];
  loading = false;

  filterEventForm: FormGroup;
  startDate = '';
  endDate = '';
  myDateRangePickerOptions: IMyDrpOptions = { dateFormat: 'dd.mm.yyyy' };
  serverErrorMessage = '';

  @Input() disableVehicleFilter: boolean;
  @Output() goCancel = new EventEmitter<boolean>();
  @Output() goFilter = new EventEmitter<EventFilter>();

  constructor(private vehicleService: VehicleService, private tagService: TagService) { }

  ngOnInit() {
    this.loading = true;
    this.serverErrorMessage = '';
    this.vehicleService.getVehicles()
      .subscribe(
        (vehicles: Vehicle[]) => {
          this.vehicles = vehicles;
          this.tagService.getTags()
              .subscribe(
                (tags) => {
                  this.tags = tags;
                  this.loading = false;
                  this.initForm();
                },
                (error) => {
                  this.loading = false;
                  this.serverErrorMessage = error;
                }
              )
        },
        (error) => {
          this.loading = false;
          this.serverErrorMessage = error;
        }
    );
   }

   initForm() {
    const startDate = '';
    const vehicle = [];
    const description = '';
    const guid = '';

    this.filterEventForm = new FormGroup({
        'guid': new FormControl(guid, [Validators.maxLength(36), Validators.minLength(3)]),
        'startDate': new FormControl(startDate),
        'vehicle': new FormControl({value: vehicle, disabled: this.disableVehicleFilter}),
        'description': new FormControl(description, Validators.maxLength(20)),
    });
  }

  setDateRange(): void {
    // Set date range (today) using the patchValue function
    const date = new Date();
    this.filterEventForm.patchValue({startDate: {
        beginDate: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        },
        endDate: {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        }
    }});
  }

  onSubmit() {
    const guid = this.filterEventForm.controls['guid'].value;
    const description = this.filterEventForm.controls['description'].value;
    const vehicle: number[] = this.filterEventForm.controls['vehicle'].value;
    const eventFilter: EventFilter = new EventFilter(guid, this.startDate, this.endDate, description, vehicle, null, null, null);
    this.goFilter.emit(eventFilter)
  }

  onCancel() {
    this.goCancel.emit(false);
  }

  onReset() {
    this.filterEventForm.reset()
  }

  onDateRangeChanged(event: IMyDateRangeModel) {
    if (event.formatted) {
      this.startDate = event.formatted.split('-')[0].trim();
      this.endDate = event.formatted.split('-')[1].trim();
    } else {
      this.startDate = '';
      this.endDate = '';
    }
  }

  clearDateRange(): void {
    // Clear the date range using the patchValue function
    this.filterEventForm.patchValue({startDate: ''});
  }
}
